@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    font-family: 'Poppins', sans-serif;
}
.font-default{
    font-family: 'Poppins', sans-serif;
}

.font-rokkit{
    font-family: 'Rokkitt', serif;
}
.font-title{
    font-family: "Satisfy", sans-serif;
}
.font-roboto{
    font-family: "Roboto", sans-serif;
}

@layer base{
    .nav-all{
        @apply text-[#898787] duration-500 hover:active font-bold text-[30px] font-title
         lg:font-normal lg:text-[18px] lg:font-mono
    }
    .nav{
        @apply relative lg:pr-[30px] lg:after:content-[''] lg:after:absolute lg:after:w-[1px] lg:after:h-full
         lg:after:bg-[#eee] lg:after:right-[15px] lg:after:top-1/2 lg:after:-translate-y-1/2
    }
   

    .active{
        @apply text-black
    }
    
    .main-title{
        @apply text-white relative mb-[30px] after:content-[''] after:absolute
         after:w-[30px] after:h-[1px] after:bg-white after:z-[2]
         after:left-1/2 after:-translate-x-1/2 after:-bottom-[10px]
    }

    .hero-image{
        @apply duration-500 opacity-0 absolute w-full h-full top-0 left-0 object-cover
    }

    .active-image{
       @apply opacity-100
    }

    .hero-text{
        @apply duration-700 active-text uppercase text-white absolute z-[3] left-1/2 font-rokkit top-[1000px] -translate-x-1/2
    }

    .chevron-right{
        @apply absolute top-1/2 -translate-y-1/2
        right-[20px] bg-[#ffffff33] cursor-pointer text-[#ffffff80]
        w-[60px] h-[60px] rounded-full z-[10] grid place-items-center text-[22px]
    }
    .chevron-left{
        @apply absolute top-1/2 -translate-y-1/2
        left-[20px] bg-[#ffffff33] cursor-pointer text-[#ffffff80]
        w-[60px] h-[60px] rounded-full z-[10] grid place-items-center text-[22px]
    }

    .active-text{
        @apply top-1/2 -translate-y-1/2

    }
    
    .sub-title{
        @apply w-fit text-gray-500 relative mb-[30px] mx-[auto] uppercase font-rokkit
         after:content-[''] after:absolute
        after:w-[30px] after:h-[1px] after:bg-gray-500 after:z-[2]
        after:left-1/2 after:-translate-x-1/2 after:-bottom-[10px]
    }

    .hero-dot{
        @apply block duration-300 w-[15px] h-[15px] rounded-full
        cursor-pointer bg-gray-400
    }

    .active-dot{
        @apply bg-main
    }

    .overlay-btn{
        @apply relative mt-[20px] duration-300 border-2 border-white w-[200px] h-[40px]
        mx-auto grid place-items-center hover:text-black before:content-[""] before:absolute
        before:w-0 hover:before:w-full before:h-full before:left-0 before:top-0 before:bg-white before:-z-[1] before:duration-300
    }

    .page-title{
        @apply text-center mb-[10px] relative font-semibold text-[40px] sm:text-[60px] font-rokkit after:content-[''] after:absolute
        after:w-[100px] after:h-[1px] after:bg-black after:z-[2]
        after:left-1/2 after:-translate-x-1/2 after:bottom-[10px]
    }

    .page-description{
        @apply text-center text-gray-500 italic max-w-[500px] mx-auto
    }

    .page-box-image{
        @apply relative overflow-hidden
         before:content-[""] before:absolute before:w-[85%] before:h-[85%]
        before:left-1/2 before:top-1/2 before:-translate-x-1/2 before:-translate-y-1/2
        before:border-[4px] before:border-white before:bg-transparent
    }

    .page-box-image img{
        @apply relative max-w-full duration-500 hover:scale-[1.5] z-[2]
    }

    .categories{
@apply mb-[30px] flex justify-center gap-[20px] flex-wrap;
}
.category{
@apply sm:min-w-[150px] h-[50px] px-[5px] rounded-[50px] grid place-items-center border-2
cursor-pointer text-center bg-light;
}
.category.active{
    @apply bg-main border-bold;
}
    
}

.hero{
    height: calc(85vh - 90px);
}

.shadow-gray{
    box-shadow: 0 0 15px 1px #ccc;
}


.slide-bottom {
    -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
      animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  
  @keyframes slide-bottom {
    0% {
      -webkit-transform: translateY(-25%);
              transform: translateY(-25%);
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  .contact-page .content{
    box-shadow: 0 0 19px 0 #ccc;
  }

 /* slide  */
 .swiper {
    width: 100% !important;
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  
  .swiper-slide {
    background-position: center !important;
    background-size: cover !important;
    width: 300px !important;
    height: 300px !important;
  }
  
  .swiper-slide img {
    display: block !important;
    width: 100% !important;
    height: 300px;
    object-fit: cover;
  }
  